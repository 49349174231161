import React from 'react';

const Footer = () => {
  return (
    <div className='text-2xl'>
      {/* <p>{new Date().getFullYear()}</p> */}
    </div>
  )
}

export default Footer